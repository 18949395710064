import Script from "next/script";
import { extractScript, extractNoScript } from "utils/StringUtils";
import { v4 as uuidv4 } from 'uuid';

type ReadyFunction = () => void;
const RenderScriptsContent =({scriptContent, scriptPosition, scriptNameKey, onReady}: { scriptContent: any, scriptPosition: string, scriptNameKey?: string, onReady?: ReadyFunction }) => {

    const strategy = 'lazyOnload';
    const currentContentArray = scriptContent?.split(/<script|<noscript>/);

    return currentContentArray.map((item: any)=>{
        let currentScript = item;
        const idScript = scriptNameKey ? scriptNameKey : uuidv4();
        const fullScript = ((`<script${currentScript}`).toString());

        let currentRef =  fullScript.includes('<script async src=') ? currentScript?.match(/src="([^"]*)/)[1] : '';
        if(!currentRef) currentRef =  fullScript.includes('<script src=') ? currentScript?.match(/src="([^"]*)/)[1] : '';

        if(currentRef){
            return <Script defer id={`${scriptPosition}-${idScript}`} src={currentRef} key={`${scriptPosition}-${idScript}`} strategy={strategy} onReady={onReady} />;
        }

        if(item.includes('</script>')) {
            currentScript = '<script' + item;
            return <Script defer id={`${scriptPosition}-${idScript}`} key={`${scriptPosition}-${idScript}`} strategy={strategy} dangerouslySetInnerHTML={{__html: extractScript(currentScript) ?? ''}} />;
        } 
        else if(item.includes('</noscript>')) {
            currentScript = '<noscript>' + item;
            return <noscript key={`no-${scriptPosition}-${idScript}`} dangerouslySetInnerHTML={{__html: extractNoScript(currentScript) ?? ''}}/>;
        }
    });
};

export default RenderScriptsContent;
